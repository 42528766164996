<template>
    <div class="customer-input-container">
        <div class="customer-input-container-top">
            <van-nav-bar :title="info.id > 0 ? '修改客户' : '新增客户'" fixed/>
        </div>
        <div class="customer-input-container-form">
            <van-cell-group>
                <van-field
                        v-model="info.title"
                        required
                        clearable
                        label="公司名称"
                        placeholder="请输入公司名称"
                />

                <van-field
                        v-model="info.employees"
                        required
                        readonly
                        label="公司人数"
                        placeholder="请选择公司人数"
                        @focus="onPickerEmployees"
                />

                <van-field
                        v-model="info.industry"
                        readonly
                        label="公司行业"
                        placeholder="请选择公司行业"
                        @focus="onPickerIndustry"
                />

                <van-field
                        v-model="info.nature"
                        readonly
                        label="公司性质"
                        placeholder="请选择公司性质"
                        @focus="onPickerNature"
                />

                <van-field :value="info.province+'/'+info.city+'/'+info.county"
                           placeholder="请选择省市区" required label="所在地区"
                           readonly @click="picker.area=true"/>

                <van-field
                        v-model="info.address"
                        required
                        clearable
                        label="联系地址"
                        placeholder="请输入联系地址"
                        type="textarea"
                        maxlength="50"
                        show-word-limit
                        autosize
                />

                <van-field
                        v-model="info.website"
                        required
                        clearable
                        label="联系部门"
                        placeholder="请输入联系部门"
                />

                <van-field
                        v-model="info.connect"
                        required
                        clearable
                        label="联系人"
                        placeholder="请输入联系人"
                />

                <van-field
                        v-model="info.mobile"
                        type="tel"
                        clearable
                        label="联系手机"
                        placeholder="请输入联系手机"
                />

                <van-field
                        v-model="info.phone"
                        clearable
                        label="联系电话"
                        placeholder="请输入联系电话"
                />

                <van-field
                        v-model="info.dept"
                        required
                        readonly
                        label="所属部门"
                        placeholder="请选择所属部门"
                        @focus="onPickerDept"
                />

                <van-field
                        v-model="info.user"
                        required
                        readonly
                        label="销售员"
                        placeholder="请选择销售员"
                        @focus="onPickerUser"
                />

                <van-field
                        v-model="info.note"
                        type="textarea"
                        clearable
                        label="备注信息"
                        placeholder="请输入备注信息"
                        maxlength="50"
                        show-word-limit
                        autosize
                />

            </van-cell-group>
        </div>
        <div class="customer-input-container-btn">
            <van-button type="info" icon="passed" size="large" @click="onSubmit"
                        style="position:fixed;bottom:0;"
                        text="提交"/>
        </div>
        <div class="customer-input-container-popup">
            <!-- 省市区选择器 -->
            <van-popup v-model="picker.area" position="bottom" :style="{ height: '50%' }">
                <areaSelector @onCancel="picker.area=false" @onConfirm="onConfirmRegion" :address.sync="info"/>
            </van-popup>
            <!-- 公司行业 -->
            <van-popup v-model="picker.industry" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="公司行业"
                        :columns="industryList"
                        @confirm="onChangeIndustry"
                        @cancel="picker.industry=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 公司性质 -->
            <van-popup v-model="picker.nature" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="公司性质"
                        :columns="natureList"
                        @confirm="onChangeNature"
                        @cancel="picker.nature=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 公司人数 -->
            <van-popup v-model="picker.employees" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="公司人数"
                        :columns="employeesList"
                        @confirm="onChangeEmployees"
                        @cancel="picker.employees=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 部门 -->
            <van-popup v-model="picker.dept" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="所属部门"
                        :columns="deptList"
                        @confirm="onChangeDept"
                        @cancel="picker.dept=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 销售员 -->
            <van-popup v-model="picker.user" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="销售员"
                        :columns="userList"
                        @confirm="onChangeUser"
                        @cancel="picker.user=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 查询界面 -->
            <van-popup v-model="picker.search" position="bottom" closeable close-icon="my iconfont icon-guanbi"
                       :style="{width:'100%',height:'100%'}" get-container="#app">
                <searchCustomer />
            </van-popup>
        </div>
    </div>
</template>

<script>
    import areaSelector from '../../common/areaSelector'
    import searchCustomer from './search'

    export default {
        name: 'customer-input',
        components: {
            areaSelector,
            searchCustomer
        },
        props: ['node', 'param'],
        data() {
            return {
                info: this.param && this.param.id ? this.param : {
                    id: 0,
                    address: '',
                    province: '省',
                    provinceId: 0,
                    city: '市',
                    cityId: 0,
                    county: '区',
                    countyId: 0,
                    dept: '',
                    deptId: 0,
                    employees: '',
                    employeesId: 0,
                    industry: '',
                    industryId: 0,
                    nature: '',
                    natureId: 0,
                    note: '',
                    phone: '',
                    connect: '',
                    mobile: '',
                    title: '',
                    user: '',
                    userId: '',
                    website: '',
                },
                provinceList: [],
                cityList: [],
                cityListCache: [],
                countryList: [],
                countryListCache: [],
                deptList: [],
                userList: [],
                userListCache: [],
                natureList: [],
                industryList: [],
                employeesList: [],
                picker: {
                    area: false,
                    nature: false,
                    industry: false,
                    employees: false,
                    dept: false,
                    user: false,
                    search: false,
                }
            }
        },
        methods: {
            onSubmit() {
                let that = this;
                that.$api.company.input(this.info).then(response => {
                    let res = response.data
                    if (res.code == 1) {
                        that.$notify({type: 'primary', message: res.msg});
                        // 独立弹出
                        if (that.node && that.node.id) {
                            that.$emit('onClose', that.node);
                            that.picker.search = true;
                        }
                        // 客户查询内弹出
                        if (that.param) {
                            if (that.param.id > 0) {
                                that.$emit('onChange', that.info);
                            } else {
                                that.$emit('onClose');
                            }
                        }
                    } else {
                        that.$notify({type: 'warning', message: res.msg})
                    }
                })
            },
            onConfirmRegion(options) {
                this.info.provinceId = options[0].id;
                this.info.province = options[0].text;
                this.info.cityId = options[1].id;
                this.info.city = options[1].text;
                this.info.countyId = options[2].id;
                this.info.county = options[2].text;
                this.picker.area = false;
            },
            onPickerNature() {
                if (this.natureList.length > 0) {
                    return this.picker.nature = true
                }
                let that = this;
                that.$api.company.loadNature().then(response => {
                    let res = response.data
                    if (res.code == 1) {
                        that.natureList = res.data
                        that.picker.nature = true
                    }
                })
            },
            onChangeNature(value) {
                this.picker.nature = false;
                this.info.natureId = value.index;
                this.info.nature = value.text;
            },
            onPickerIndustry() {
                if (this.industryList.length > 0) {
                    return this.picker.industry = true;
                }
                let that = this;
                that.$api.company.loadIndustry().then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.industryList = res.data;
                        that.picker.industry = true;
                    }
                })
            },
            onChangeIndustry(value) {
                this.picker.industry = false;
                this.info.industryId = value.index;
                this.info.industry = value.text;
            },
            onPickerEmployees() {
                if (this.employeesList.length > 0) {
                    return this.picker.employees = true;
                }
                let that = this;
                that.$api.company.loadEmployees().then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.employeesList = res.data;
                        that.picker.employees = true;
                    }
                })
            },
            onChangeEmployees(value) {
                this.picker.employees = false;
                this.info.employeesId = value.index;
                this.info.employees = value.text;
            },
            onPickerDept() {
                if (this.deptList.length > 0) {
                    return this.picker.dept = true
                }
                let that = this;
                that.$api.user.loadDept().then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.deptList = res.data;
                        that.picker.dept = true;
                    }
                })
            },
            onChangeDept(value) {
                // 清空已选销售员
                if (this.info.deptId !== value.index) {
                    this.info.userId = '';
                    this.info.user = '';
                }
                this.picker.dept = false;
                this.info.deptId = value.index;
                this.info.dept = value.text;
            },
            onPickerUser() {
                if (this.info.deptId == 0)
                    return this.$toast.fail('请选择所属部门');
                let that = this;
                let cache = [];
                if (this.userListCache.length > 0) {
                    this.userListCache.forEach(function (v) {
                        if (v.deptId == that.info.deptId) {
                            cache = v.list;
                        }
                    })
                }
                if (cache.length == 0) {
                    that.$api.user.loadUserByDept(that.info.deptId).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.userListCache.push({deptId: that.info.deptId, list: res.data});
                            that.userList = res.data;
                            that.picker.user = true;
                        } else {
                            that.$toast(res.msg);
                        }
                    })
                } else {
                    this.userList = cache;
                    this.picker.user = true;
                }
            },
            onChangeUser(value) {
                this.picker.user = false;
                this.info.userId = value.index;
                this.info.user = value.text;
            },
        }
    }
</script>

<style scoped>

    .customer-input-container-form {
        position: fixed;
        width: 100%;
        top: 46px;
        bottom: 50px;
        overflow-y: scroll;
    }

</style>
