<template>
    <div class="customer-search-container">
        <div class="customer-search-container-top">
            <van-nav-bar title="客户管理" fixed/>
        </div>
        <div class="customer-search-container-search">
            <van-search placeholder="搜索一下" :disabled="true" show-action @click="picker.search=true">
                <div slot="action" @click="onAdd">新增客户</div>
            </van-search>
        </div>
        <div class="customer-search-container-list">
            <van-pull-refresh v-model="freshing" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text="finishedText"
                        @load="onLoad"
                >
                    <van-swipe-cell v-for="(item ,index) in list" v-bind:key="index">
                        <template slot="left">
                            <van-button square type="primary" icon="edit" text="修改" @click="onEdit(item)"/>
                        </template>
                        <van-cell :border="true" :value="item.userName" :label="item.connect">
                            <template slot="title">
                                <van-tag round plain type="success" v-if="item.properties == 0">新客</van-tag>
                                <van-tag round plain type="primary" v-if="item.properties == 2">老客</van-tag>
                                <span class="custom-title">{{item.title}}</span>
                            </template>
                        </van-cell>
                        <template slot="right">
                            <van-button square type="primary" icon="add-o" text="试吃" @click="onAddOrder(item)"
                                        v-if="item.properties != 1"/>
                            <van-button square type="info" icon="exchange" text="老客"
                                        @click="onChangeStatus(item, index, 2)"
                                        v-if="item.properties == 0"/>
                            <van-button square type="danger" icon="delete" text="删除"
                                        @click="onDelete(item)"
                                        v-if="item.properties == 0"/>
                            <van-button square type="warning" icon="my iconfont icon-taocan" text="套餐"
                                        @click="onPackage(item)"
                                        v-if="item.properties != 1 && auth.indexOf('/sale/customer/package') >= 0"/>
                        </template>
                    </van-swipe-cell>
                </van-list>
            </van-pull-refresh>
        </div>
        <div class="customer-search-container-pagination" v-show="pages>0">
            <van-pagination
                    v-model="option.page"
                    :page-count="pages"
                    mode="simple"
                    @change="onLoad"
            />
        </div>
        <!-- 弹出区域 -->
        <div class="customer-search-container-popup">
            <!-- 搜索 -->
            <van-popup v-model="picker.search" position="right" :round="true" :style="{width:'80%',height:'100%'}">
                <!-- 搜索区域 -->
                <van-cell-group>
                    <van-field
                            v-model="option.company"
                            label="公司名称"
                            placeholder="请输入公司名称"
                            clearable
                    />
                    <van-field
                            v-model="option.connect"
                            label="联系人"
                            placeholder="请输入联系人"
                            clearable
                    />
                    <van-field
                            v-show="deptList.length > 2"
                            v-model="option.deptName"
                            label="所属部门"
                            placeholder="请选择所属部门"
                            @focus="picker.dept=true"
                            readonly
                    />
                    <van-field
                            v-show="deptList.length > 2"
                            v-model="option.userName"
                            label="销售员"
                            placeholder="请选择销售员"
                            @focus="onPickerUser"
                            readonly
                    />
                    <van-field
                            v-model="option.propertiesName"
                            label="客户属性"
                            placeholder="请选客户属性"
                            @focus="picker.properties=true"
                            readonly
                    />
                    <van-button type="info" icon="passed" size="large" @click="onSearch"
                                style="position:fixed;bottom:0;"
                                text="查询"/>
                </van-cell-group>
            </van-popup>
            <!-- 部门 -->
            <van-popup v-model="picker.dept" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="所属部门"
                        :columns="deptList"
                        @confirm="onChangeDept"
                        @cancel="picker.dept=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 销售员 -->
            <van-popup v-model="picker.user" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="销售员"
                        :columns="userList"
                        @confirm="onChangeUser"
                        @cancel="picker.user=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 客户属性 -->
            <van-popup v-model="picker.properties" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="客户属性"
                        :columns="propertiesList"
                        @confirm="onChangeProperties"
                        @cancel="picker.properties=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 修改信息 -->
            <van-popup v-model="picker.edit" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left"
                       :style="{width:'100%',height:'100%'}">
                <inputCustomer :param="customer" :key="customer.id" @onChange="onEditComplete"
                               @onClose="onAddComplete"/>
            </van-popup>
            <!-- 新增订单 -->
            <van-popup v-model="picker.order" position="bottom" closeable close-icon="my iconfont icon-guanbi"
                       :style="{width:'100%',height:'100%'}">
                <addOrder :customer="customer" :key="customer.id" @onAdd="picker.order=false"/>
            </van-popup>
            <!-- 套餐管理 -->
            <van-popup v-model="picker.package" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left"
                       :style="{width:'100%',height:'100%'}">
                <packageManage :param="customer" :key="customer.id" @onClose="picker.package=false"/>
            </van-popup>
        </div>
    </div>
</template>

<script>
    import inputCustomer from './input'
    import addOrder from '../order/input'
    import packageManage from './package/search'

    export default {
        name: "customer-search",
        components: {
            inputCustomer,
            addOrder,
            packageManage,
        },
        data() {
            return {
                auth: this.$store.state.user.auth || [],
                freshing: false,
                list: [],
                deptList: [],
                userList: [],
                userListCache: [],
                propertiesList: [{index: '', text: '全部'}, {index: 0, text: '新客户'}, {index: 2, text: '老客户'}, {
                    index: 1,
                    text: '已舍弃'
                }],
                loading: false,
                finished: true,
                finishedText: '',
                pages: 0,
                option: {
                    page: 1,
                    company: '',
                    connect: '',
                    deptId: 0,
                    deptName: '全部',
                    userId: 0,
                    userName: '全部',
                    properties: '',
                    propertiesName: '全部',
                },
                picker: {
                    search: false,
                    dept: false,
                    user: false,
                    properties: false,
                    edit: false,
                    order: false,
                    package: false
                },
                customer: {
                    id: 0
                },
                dataCache: [],
            }
        },
        mounted: function () {
            this.init();
        },
        methods: {
            init() {
                this.loadDept();
                this.onLoad();
            },
            loadDept() {
                let that = this;
                that.$api.user.loadDept().then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        res.data.unshift({index: 0, text: '全部'});
                        that.deptList = res.data;
                    }
                })
            },
            onChangeDept(value) {
                // 清空已选销售员
                if (this.option.deptId !== value.index) {
                    this.option.userId = 0;
                    this.option.userName = '全部';
                }
                this.picker.dept = false;
                this.option.deptId = value.index;
                this.option.deptName = value.text;
            },
            onPickerUser() {
                if (this.option.deptId == 0)
                    return this.$toast.fail('请选择所属部门');
                let that = this;
                let cache = [];
                if (this.userListCache.length > 0) {
                    this.userListCache.forEach(function (v) {
                        if (v.deptId == that.option.deptId) {
                            cache = v.list;
                        }
                    })
                }
                if (cache.length == 0) {
                    that.$api.user.loadUserByDept(that.option.deptId).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            res.data.unshift({index: 0, text: '全部'});
                            that.userListCache.push({deptId: that.option.deptId, list: res.data});
                            that.userList = res.data;
                            that.picker.user = true;
                        } else {
                            that.$toast(res.msg);
                        }
                    })
                } else {
                    this.userList = cache;
                    this.picker.user = true;
                }
            },
            onChangeUser(value) {
                this.picker.user = false;
                this.option.userId = value.index;
                this.option.userName = value.text;
            },
            onChangeProperties(value) {
                this.picker.properties = false;
                this.option.properties = value.index;
                this.option.propertiesName = value.text;
            },
            onSearch() {
                this.option.page = 1;
                this.onLoad();
            },
            onRefresh() {
                this.option.page = 1;
                this.freshing = false;
                this.onLoad();
            },
            onLoad() {
                let that = this;
                that.picker.search = false;
                that.list = [];
                that.loading = true;
                that.finished = false;
                that.$api.company.search(that.option).then(response => {
                    that.loading = false;
                    that.finished = true;
                    let res = response.data;
                    if (res.code == 1) {
                        if (res.data.list.length == 0) {
                            that.finishedText = '暂无数据';
                        } else {
                            that.finishedText = '';
                        }
                        that.pages = res.data.pages;
                        that.list = res.data.list;
                    } else {
                        that.$toast(res.msg);
                    }
                })
            },
            onAdd() {
                this.customer.id = 0;
                this.picker.edit = true;
            },
            onAddComplete() {
                this.picker.edit = false;
                this.onSearch();
            },
            onEdit(item) {
                let that = this;
                let cache = false;
                if (that.dataCache.length > 0) {
                    that.dataCache.forEach(function (v) {
                        if (v.id == item.id) {
                            cache = true;
                            that.customer = v.data;
                            that.picker.edit = true;
                        }
                    });
                }
                if (!cache) {
                    that.$api.company.detail(item.id).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.dataCache.push({id: item.id, data: res.data});
                            that.customer = res.data;
                            that.picker.edit = true;
                        } else {
                            that.$notify({type: 'warning', message: res.msg});
                        }
                    })
                }
            },
            onEditComplete(data) {
                let that = this;
                that.picker.edit = false;
                that.list.forEach(function (v, k) {
                    if (v.id == data.id) {
                        that.list[k] = data;
                    }
                });
                if (that.dataCache.length > 0) {
                    that.dataCache.forEach(function (v, k) {
                        if (v.id == data.id) {
                            that.dataCache[k].data = data;
                        }
                    })
                }
            },
            onPackage(item) {
                let that = this;
                let cache = false;
                if (that.dataCache.length > 0) {
                    that.dataCache.forEach(function (v) {
                        if (v.id == item.id) {
                            cache = true;
                            that.customer = v.data;
                            that.picker.package = true;
                        }
                    });
                }
                if (!cache) {
                    that.$api.company.detail(item.id).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.dataCache.push({id: item.id, data: res.data});
                            that.customer = res.data;
                            that.picker.package = true;
                        } else {
                            that.$notify({type: 'warning', message: res.msg});
                        }
                    })
                }
            },
            onAddOrder(item) {
                this.customer = item;
                this.picker.order = true;
            },
            onChangeStatus(item, index, status) {
                let that = this;
                that.$api.company.change({id: item.id, status: status}).then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.onLoad();
                        that.$notify({type: 'primary', message: res.msg});
                    } else {
                        that.$notify({type: 'warning', message: res.msg});
                    }
                })
            },
            onDelete(item) {
                let that = this;
                that.$api.company.delete({id: item.id}).then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.onLoad();
                        that.$notify({type: 'primary', message: res.msg});
                    } else {
                        that.$notify({type: 'warning', message: res.msg});
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .customer-search-container {
        padding-top: 46px;
    }

    .customer-search-container-top {
        position: fixed;
        width: 100%;
        height: 46px;
        top: 0;
    }

    .customer-search-container-list {
        position: fixed;
        width: 100%;
        top: 100px;
        bottom: 40px;
        overflow-y: scroll;
    }

    .customer-search-container-pagination {
        position: fixed;
        width: 100%;
        height: 40px;
        bottom: 0;
    }

    .van-cell__title {
        flex: 2
    }

    .van-swipe-cell .van-button {
        height: 100%;
    }
</style>
